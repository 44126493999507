.mySwiper {
  display: flex;
  justify-content: center;
}

.nft-slide {
  position: relative;
  overflow: hidden;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1),
    0 10px 15px rgba(0, 0, 0, 0.1),
    0 20px 25px rgba(0, 0, 0, 0.1);
}

.nft-slide img {
  margin: 0 auto;
  transition: transform 0.3s ease-in-out;
}

.nft-slide:hover img {
  transform: scale(1.05);
}

.nft-slide .text-overlay {
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);
  padding: 16px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  text-align: center;
  z-index: 20;
  border-radius: 0.25rem;
}

.nft-slide::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.nft-slide img {
  position: relative;
  z-index: 15;
}